import React from 'react';

interface Props {
   className?: string;
}

export const DirectionIcon: React.FC<Props> = ({ className }) => {
   return (
      <svg className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
         <path d="M1.32189 12.7772L11.198 22.6776C11.6259 23.1066 12.3255 23.1077 12.7534 22.6798L22.6768 12.7794C22.7793 12.6772 22.8605 12.5558 22.916 12.4222C22.9715 12.2886 23 12.1453 23 12.0006C23 11.8559 22.9715 11.7126 22.916 11.579C22.8605 11.4453 22.7793 11.324 22.6768 11.2218L12.7534 1.32143C12.6512 1.21933 12.5299 1.13839 12.3963 1.08324C12.2628 1.02808 12.1197 0.999797 11.9752 1C11.8308 1.00021 11.6878 1.02889 11.5544 1.08443C11.421 1.13996 11.2999 1.22125 11.198 1.32363L1.32189 11.224C1.21984 11.3259 1.13889 11.447 1.08366 11.5802C1.02843 11.7135 1 11.8563 1 12.0006C1 12.1448 1.02843 12.2877 1.08366 12.4209C1.13889 12.5542 1.21984 12.6753 1.32189 12.7772V12.7772ZM8.69981 9.80052H14.2V7.60045L17.5001 10.9006L14.2 14.2007V12.0006H10.8999V16.4007H8.69981V9.80052Z" />
      </svg>
   );
};
